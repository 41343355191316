<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
    <router-link to="/mapview">MAP</router-link>
  </nav> -->
  <router-view/>
</template>

<style>
#app {
  display: flex;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}

html,body,#app,.el-container{
            /*设置内部填充为0，几个布局元素之间没有间距*/
            padding: 0px;
            /*外部间距也是如此设置*/
            margin: 0px;
            /*统一设置高度为100%*/
            height: 100%;
        }

/* nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
