import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/common/layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login',
    component: HomeView,
    meta: {
      title: 'X波段相控阵天气雷达——产品软件',
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '@/views/common/login/index.vue'),
        meta: {
          title: '登录页面',
        },
      },
      {
        path: '/singleSite',
        name: 'singleSite',
        component: () => import(/* webpackChunkName: "about" */ '@/views/singleSite/index.vue'),
        meta: {
          title: '单站产品',
        },
      },
      {
        path: '/multiSite',
        name: 'multiSite',
        component: () => import(/* webpackChunkName: "about" */ '@/views/multiSite/index.vue'),
        meta: {
          title: '组网产品',
        }
      },
      {
        path: '/healthStatus',
        name: 'healthStatus',
        component: () => import(/* webpackChunkName: "about" */ '@/views/healthStatus/index.vue'),
        meta: {
          title: '健康状态',
        }
      },
      {
        path: '/productDist',
        name: 'productDist',
        component: () => import(/* webpackChunkName: "about" */ '@/views/productDist/index.vue'),
        meta: {
          title: '数据分发',
        }
      },
      {
        path: '/dataRequest',
        name: 'dataRequest',
        component: () => import(/* webpackChunkName: "about" */ '@/views/dataRequest/index.vue'),
        meta: {
          title: '数据申请',
        }
      },
      {
        path: '/paramConfig',
        name: 'paramConfig',
        component: () => import(/* webpackChunkName: "about" */ '@/views/paramConfig/index.vue'),
        meta: {
          title: '参数配置',
        }
      },
      {
        path: '/userMgr',
        name: 'userMgr',
        component: () => import(/* webpackChunkName: "about" */ '@/views/userMgr/index.vue'),
        meta: {
          title: '用户管理',
        }
      },
      {
        path: '/logView',
        name: 'logView',
        component: () => import(/* webpackChunkName: "about" */ '@/views/logView/index.vue'),
        meta: {
          title: '日志信息',
        }
      },
      {
        path: '/3dView',
        name: '3dView',
        component: () => import(/* webpackChunkName: "about" */ '@/views/mapview3D/index.vue'),
        meta: {
          title: '三维显示',
        }
      },
      {
        path: '/jsapiDownload',
        name: 'jsapiDownload',
        component: () => import(/* webpackChunkName: "about" */ '@/views/singleSite/jsapiDownload.vue'),
        meta: {
          title: 'playGround'
        }
      },
      {
        path: '/map',
        name: 'map',
        component: () => import(/* webpackChunkName: "about" */ '@/views/singleSite/map.vue'),
        meta: {
          title: 'map'
        }
      },
      {
        path: '/test',
        name: 'test',
        component: () => import(/* webpackChunkName: "about" */ '@/views/test/index.vue'),
        meta: {
          title: 'test'
        }
      },
      {
        path: '/upload',
        name: 'upload',
        component: () => import(/* webpackChunkName: "about" */ '@/views/test/upload.vue'),
        meta: {
          title: 'upload'
        }
      },

    ]
  },
  

  // {
  //   path: '/',
  //   redirect: '/mapview',
  // },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});

export default router
