import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import VueAMapLoca from '@vuemap/vue-amap-loca';
// import VueAMapExtra from '@vuemap/vue-amap-extra';
import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
import { apiRequest } from '@/api/api.js';
import request from '@/utils/request';
import VueECharts from 'vue-echarts';
import 'echarts'; // 引入 ECharts 核心库
import "echarts-gl";
const app = createApp(App)


initAMapApiLoader({
  offline: true,
  // key: '183fba6bb4578e17e4822487981c7117',
  // securityJsCode: 'cae5aa4ee031616ed2d6e357b5a2d9a4', // 新版key需要配合安全密钥使用
  // Loca:{
  //  version: '2.0.0'
  // } // 如果需要使用loca组件库，需要加载Loca
})

// Axios
app.config.globalProperties.$http = request;
app.config.globalProperties.axios = axios

const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
// const resizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends resizeObserver {
//   constructor(callback) {
//     callback = debounce(callback, 200);
//     super(callback);
//   }
// };

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus, { locale: zhCn })
app.use(router)
app.use(VueAMap)
app.use(VueAMapLoca)
// app.use(VueAMapExtra)
app.component('v-chart', VueECharts);
app.mount('#app')