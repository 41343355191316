import axios from 'axios';
import router from '@/router'; // 引入 Vue Router
import { ElMessage } from 'element-plus'

// 创建 Axios 实例
const service = axios.create({
    // baseURL: 'http://127.0.0.1:3000',
    // baseURL: 'http://10.128.41.52:8088/api',
    baseURL: localStorage.getItem("backendAddress") || 'http://127.0.0.1:3000',
    // baseURL: '/api',
    timeout: 50000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json'
    }
});

// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // 在发送请求之前做点什么，例如添加 Token
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['token'] = `${token}`;
        }
        return config;
    },
    (error) => {
        // 对请求错误做点什么
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code && res.code !== 200 && res.code !== 10001 && res.code !== 10008) {
            ElMessage({
                showClose: true,
                message: res.msg || '后台接口返回出错',
                type: "error"
            });
            return Promise.reject(res.msg || '后台接口返回出错')
        }
        return response
    },
    (error) => {

        // 对响应错误做点什么
        if (error.response.status === 403) {
            // 这里是业务逻辑，可能是 Token 过期，或权限问题
            // 可以清除本地存储的 token 或其他信息
            sessionStorage.clear();

            // 跳转到登录页
            router.push({ name: 'login' }); // 假设路由名字为 'login'
            return
        }

        ElMessage({
            showClose: true,
            message: '后台接口返回出错',
            type: "error"
        });
        return Promise.reject(error)


    }
);

export default service;
